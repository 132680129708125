import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import { useIntegrations } from "../IntegrationContext";

import { IntegrationSwitch } from "./IntegrationSwitch";
import { AddEditIntegration } from "../AddEditIntegration";
import { EditIntegration } from "./EditIntegration";

export const Stream = () => {
  const [isAddActive, setIsAddActive] = useState(false);
  const integrations = useIntegrations();
  return (
    <>
      <ButtonGroup color="primary" variant="outlined">
        <Button
          variant={isAddActive ? "contained" : "outlined"}
          onClick={() => setIsAddActive(!isAddActive)}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </ButtonGroup>

      {isAddActive && (
        <AddEditIntegration onSave={() => setIsAddActive(false)} />
      )}
      {integrations && integrations.length ? (
        integrations.map((integration, index) => (
          <Box mt={2} p={2}>
            <Card key={integration.key || index}>
              <CardHeader title={integration.title} />
              <CardContent>
                <IntegrationSwitch integration={integration} />
              </CardContent>
              <EditIntegration integration={integration} />
            </Card>
          </Box>
        ))
      ) : (
        <Typography>No integrations</Typography>
      )}
    </>
  );
};
