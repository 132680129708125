import gql from "graphql-tag";

import { useQuery } from "@apollo/client";

const GET_COMMITS = gql`
  query ($owner: String!, $name: String!)
    {
      repository(owner: $owner, name: $name) {
        ref(qualifiedName: "master") {
          target {
            ... on Commit {
              history(first: 100) {
                nodes {
                  id
                  message
                  commitUrl
                }
              }
            }
          }
        }
      }
    }
  `;

export const useCommits = (
  owner,
  name
) =>
  useQuery(GET_COMMITS, {
    variables: {
      owner,
      name,
    },
  });
