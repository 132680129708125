import React from "react";
import Typography from "@material-ui/core/Typography";

import { GitHub } from "../Integrations/GitHub";

export const IntegrationSwitch = ({ integration }) => {
    switch (integration.type) {
      case "github":
        return <GitHub title={integration.title} {...integration.settings} />;
      default:
        return <Typography>Unknown Integration: {integration.type}</Typography>;
    }
  };
  