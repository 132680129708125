import React, { createContext, useContext, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

const IntegrationsContext = createContext();
const SetIntegrationsContext = createContext();

const SetProvider = React.memo(({ ...props }) => (
  <SetIntegrationsContext.Provider {...props} />
));

export const IntegrationsProvider = (props) => {
  const [integrations, setIntegrations] = useState(readFromLocalStorage());

  return (
    <IntegrationsContext.Provider value={integrations}>
      <SetProvider {...props} value={setIntegrations} />
    </IntegrationsContext.Provider>
  );
};

export const useIntegrations = () => useContext(IntegrationsContext);
export const useAddEditIntegration = () => {
  const set = useContext(SetIntegrationsContext);
  return useCallback(
    (id, owner, name, token) => {
      addEditItem(id, owner, name, token);
      set(readFromLocalStorage());
    },
    [set]
  );
};

export const useDeleteIntegration = () => {
  const set = useContext(SetIntegrationsContext);
  return useCallback(
    (id) => {
      deleteItem(id);
      set(readFromLocalStorage());
    },
    [set]
  );
};

// TODO: event listener for external changes `window.addEventListener("storage", handleStorageChange)`

export const useClearIntegrations = () => {
  const set = useContext(SetIntegrationsContext);
  return useCallback(() => {
    localStorage.removeItem("integrations");
    set(readFromLocalStorage());
  }, [set]);
};

const readFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("integrations")) || [];

const addEditItem = (id, owner, name, token) => {
  const integrations = JSON.parse(localStorage.getItem("integrations")) || [];
  console.log(integrations);
  const index =
    id && integrations.findIndex((integration) => integration.id === id);
  if (id && index < 0) {
    throw new Error(`Integration not found in localStorage: ${id}`);
  }
  const newIntegrations = id ? integrations.splice() : [...integrations];
  const newItem = {
    id: id || uuidv4(),
    type: "github",
    title: "GitHub",
    settings: {
      owner: owner,
      name: name,
      token: token,
    },
  };

  if (index) {
    newIntegrations.splice(index, 1, newItem);
  } else {
    newIntegrations.push(newItem);
  }

  localStorage.setItem("integrations", JSON.stringify(newIntegrations));
};

const deleteItem = (id) => {
  console.log("delete", id);
  const integrations = JSON.parse(localStorage.getItem("integrations")) || [];
  const newIntegrations = integrations.filter(
    (integration) => integration.id !== id
  );
  localStorage.setItem("integrations", JSON.stringify(newIntegrations));
};
