import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { IntegrationsProvider } from "./IntegrationContext";
import { Stream } from "./Stream";

function App() {
  return (
    <>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography>yday.io</Typography>
        </Toolbar>
      </AppBar>
      <Box mt={4}>
        <Container maxWidth="sm">
          <IntegrationsProvider>
            <Stream />
          </IntegrationsProvider>
        </Container>
      </Box>
    </>
  );
}

export default App;
