import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { useAddEditIntegration } from "../IntegrationContext";

export const AddEditIntegration = ({ onSave, integration }) => {
  const [owner, setOwner] = useState(integration?.settings?.owner || '');
  const [name, setName] = useState(integration?.settings?.name || '');
  const [token, setToken] = useState(integration?.settings?.token || '');
  const addEditIntegration = useAddEditIntegration();
  return (
    <Box>
      <TextField required label="Owner" value={owner} onChange={e => setOwner(e.target.value)}/>
      <TextField required label="Name" value={name} onChange={e => setName(e.target.value)} />
      <TextField required label="Access key" value={token} onChange={e => setToken(e.target.value)} />
      <Button
        onClick={() => {
          addEditIntegration(integration?.id, owner, name, token);
          onSave && onSave();
        }}
        startIcon={<SaveIcon />}
      >
        Save
      </Button>
    </Box>
  );
};
