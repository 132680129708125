import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Spinner } from './Spinner';

const spinnerClassName = 'icon-loading';

const StyledContainer = styled('div')({
  position: 'relative',
  paddingBottom: '2px',
  minHeight: '50px',
});

const Innerwrap = styled('div')(({ timeIn, timeOut, backgroundColor, backgroundColorOpacity }) => ({
  position: 'absolute',
  height: '100%',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  [`& .${spinnerClassName}`]: {
    position: 'absolute',
    zIndex: 101,
    opacity: 1,
    display: 'block',
    height: '100%',
    maxHeight: 'calc(50vh + 100px)',
    transition: `opacity ${timeIn}ms ease-in-out`,
    willChange: 'opacity',
  },
  '& .overlay': {
    zIndex: 100,
    transition: `background-color ${timeIn}ms ease-in-out`,
    willChange: 'background-color',
    backgroundColor: `rgba(
      255,
      255,
      255,
      ${backgroundColorOpacity})`,
  },
  '.fade-enter': {
    '& .overlay': {
      backgroundColor: `rgba(
        255,
        255,
        255,
        0)`,
      transitionDuration: `${timeIn}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 0,
      transitionDuration: `${timeIn}ms`,
      transitionDelay: `${timeIn / 2}ms`,
    },
  },
  '.fade-enter-active': {
    '& .overlay': {
      backgroundColor: `rgba(
        255,
        255,
        255,
        ${backgroundColorOpacity})`,
      transitionDuration: `${timeIn}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 1,
      transitionDuration: `${timeIn}ms`,
      transitionDelay: `${timeIn / 2}ms`,
    },
  },
  '.fade-exit': {
    '& .overlay': {
      backgroundColor: `rgba(
        255,
        255,
        255,
        ${backgroundColorOpacity})`,
      transitionDuration: `${timeOut}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 1,
      transitionDuration: `${timeOut}ms`,
    },
  },
  '.fade-exit-active': {
    '& .overlay': {
      backgroundColor: `rgba(
        255,
        255,
        255,
        0)`,
      transitionDuration: `${timeOut}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 0,
      transitionDuration: `${timeOut}ms`,
    },
  },
}));

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  height: '100%',
  width: '100%',
});

export const LoadingBox = ({
  children,
  backgroundColor = '#FFF',
  backgroundColorOpacity =  0.85,
  title,
  loading = false,
  spinnerColor = '#000',
  timeIn = 800,
  timeOut = 200,
  ...props
}) => (
  <StyledContainer {...props}>
    <CSSTransition timeout={timeOut} classNames="fade" in={loading} unmountOnExit>
      <Innerwrap
        backgroundColor={backgroundColor}
        backgroundColorOpacity={backgroundColorOpacity}
        loading={loading}
        timeIn={timeIn}
        timeOut={timeOut}
      >
        <Spinner title={title} className={spinnerClassName} fill={spinnerColor} width="50px" height="50px" />
        <Overlay className="overlay" />
      </Innerwrap>
    </CSSTransition>
    {children}
  </StyledContainer>
);
