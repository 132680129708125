import React, { useMemo } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";

import Alert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import { LoadingBox } from "../../LoadingBox";
import ListItem from "@material-ui/core/ListItem";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ListItemText from "@material-ui/core/ListItemText";

import { Error } from "../../Error";
import { useCommits } from "./useCommits";

const Commits = ({ owner, name, title }) => {
  const { loading, data, error } = useCommits(owner, name);
  return (
    <LoadingBox loading={loading}>
      {error && (
        <Alert severity="error">
          <Error gqlError={error} />
        </Alert>
      )}

      {data && (
        <List>
          {data.repository.ref.target.history.nodes.map((commit) => (
            <ListItem divider key={commit.id}>
              <ListItemText primary={commit.message} />
            </ListItem>
          ))}
        </List>
      )}
    </LoadingBox>
  );
};

export const GitHub = ({ token, ...params }) => {
  const githubClient = useMemo(
    () =>
      new ApolloClient({
        link: createHttpLink({
          uri: "https://api.github.com/graphql",
          headers: {
            authorization: `Bearer ${token}`,
          },
        }),
        cache: new InMemoryCache(),
      }),
    [token]
  );

  return (
    <ApolloProvider client={githubClient}>
      <Commits {...params} />
    </ApolloProvider>
  );
};
