import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useDeleteIntegration } from "../IntegrationContext";

import { AddEditIntegration } from "../AddEditIntegration";

export const EditIntegration = ({ integration }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const deleteIntegration = useDeleteIntegration();
  return (
    <Box>
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={() => setIsDeleteConfirmOpen(false)}
        >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Delete Integration?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsDeleteConfirmOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {deleteIntegration(integration?.id); setIsDeleteConfirmOpen(false)}} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
      <CardActions color="secondary" variant="outlined">
        <ButtonGroup>
            <Button
            color="secondary"
            variant={isEditMode ? "contained" : "outlined"}
            startIcon={<EditIcon />}
            onClick={() => setIsEditMode(!isEditMode)}
            >
            Edit
            </Button>
            <Button
            onClick={() => setIsDeleteConfirmOpen(true)}
            startIcon={<DeleteIcon />}
            >
            Delete
            </Button>
        </ButtonGroup>
      </CardActions>
      {isEditMode && (
        <Box mt={2}>
          <AddEditIntegration
            onSave={() => setIsEditMode(false)}
            integration={integration}
          />
        </Box>
      )}
    </Box>
  );
};
